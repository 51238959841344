import React, { useRef, useEffect, useState } from "react"
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet"
import L from "leaflet"
import "leaflet/dist/leaflet.css"
import iconUrl from "./location-pin.png"

const SimpleMap = ({ coordinates, inputImage }) => {
  const mapRef = useRef(null)
  const [position, setPosition] = useState([52, -100])
  const [markerKey, setMarkerKey] = useState(0)

  useEffect(() => {
    if (coordinates && coordinates.latitudes && coordinates.longitudes) {
      const latitudes = coordinates.latitudes
      const longitudes = coordinates.longitudes

      if (latitudes.length > 0 && longitudes.length > 0) {
        const firstLatitude = latitudes[0]
        const firstLongitude = longitudes[0]
        setPosition([firstLatitude, firstLongitude])
        setMarkerKey((prevKey) => prevKey + 1)
      }
    }
  }, [coordinates])

  const handleSetPosition = (lat, lng) => {
    setPosition([lat, lng])
    setMarkerKey((prevKey) => prevKey + 1)
  }

  const SetViewOnPositionChange = ({ position }) => {
    const map = useMap()
    useEffect(() => {
      map.setView(position)
    }, [position])
    return null
  }

  return (
    <div className="w-full h-96 relative">
      <MapContainer
        center={position}
        zoom={12}
        ref={mapRef}
        className="w-full h-full z-0 rounded-lg"
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {position && (
          <>
            <Marker
              key={markerKey}
              position={position}
              icon={L.icon({
                iconUrl,
                iconSize: [50, 50],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
                shadowSize: [41, 41],
              })}
            >
              <Popup>AI location guess</Popup>
            </Marker>
            <SetViewOnPositionChange position={position} />
          </>
        )}
      </MapContainer>
      <div className="absolute bottom-0 left-0 m-2 z-50">
        <img
          className="max-w-[200px] max-h-40 w-auto h-auto rounded-lg shadow-2xl"
          src={inputImage}
          alt="uploaded"
        />
      </div>
    </div>
  )
}

export default SimpleMap
