import React from "react"
import { useAuth } from "../context/AuthContext"
import FAQs from "./Faq"

export default function Pricing() {
  const { user } = useAuth()
  const referrer = sessionStorage.getItem("referrer")

  return (
    <div className="my-24">
      {user?.email && (
        <div>
          <stripe-pricing-table
            //data-rewardful
            customer-email={user?.email}
            client-reference-id={referrer}
            pricing-table-id="prctbl_1Pc4dqJHQztoJRXD3d44x372"
            publishable-key="pk_live_51Pc4EEJHQztoJRXDMu0CKL3q0n64z8SaiYwuN3t2RN97LN04jor5mflklD4kvd9vuCJsyTNaEI3Pdc3IdiBIXX5m00ZhP33Lts"
          ></stripe-pricing-table>
          <h1 className="my-12 mt-24 text-3xl text-white font-semibold text-center">
            One-time purchase
          </h1>
          <stripe-pricing-table
            //data-rewardful
            customer-email={user?.email}
            client-reference-id={referrer}
            pricing-table-id="prctbl_1Pc4p7JHQztoJRXDwELCUSNV"
            publishable-key="pk_live_51Pc4EEJHQztoJRXDMu0CKL3q0n64z8SaiYwuN3t2RN97LN04jor5mflklD4kvd9vuCJsyTNaEI3Pdc3IdiBIXX5m00ZhP33Lts"
          ></stripe-pricing-table>
        </div>
      )}
      {/* <FAQs /> */}
    </div>
  )
}
