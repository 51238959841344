import React, { useEffect, useState } from "react"
import { useAuth } from "../../context/AuthContext"
import { firestore, serverTimestamp } from "../../firebase"
import { telegram } from "../../utils/telegram"
import Recent from "./Recent"
import Popup from "../Sign/Popup"
import { useHistory } from "react-router-dom"
import { uploadToR2 } from "../../components/uploadToR2"

export default function Create() {
  const { user } = useAuth()

  const [isLoading, setisLoading] = useState(false)
  const [error, setError] = useState(false)
  const [creditCost, setCreditCost] = useState(5)
  const [showPopup, setShowPopup] = useState(false)

  const [selectedFile, setSelectedFile] = useState({
    file: null,
    width: 0,
    height: 0,
    fileUrl: "",
  })

  const history = useHistory()

  useEffect(() => {
    if (selectedFile) {
      setCreditCost(1)
    }
  }, [selectedFile])

  const handleFileChange = (event) => {
    setError(null)
    const file = event.target.files[0]
    const fileUrl = URL.createObjectURL(file)
    const img = new Image()

    img.onload = () => {
      setSelectedFile({
        file: file,
        fileUrl: fileUrl,
      })
    }
    img.src = fileUrl
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  const handleDrop = (e) => {
    e.preventDefault()

    const files = e.dataTransfer.files

    if (files.length > 0) {
      const file = files[0]
      const fileUrl = URL.createObjectURL(file)
      const img = new Image()

      img.onload = () => {
        setSelectedFile({
          file: file,
          fileUrl: fileUrl,
        })
      }

      img.src = fileUrl
    }
  }

  const createImageDocument = async () => {
    setError(null)
    setisLoading(true)
    if (!user) {
      telegram("Create.js, createImageDocument, user not found!, user: " + user)
      setError("Register to get tokens.")
      setisLoading(false)
      return
    }

    if (user.balance <= 0 || user.balance < creditCost) {
      setError("Not enough tokens!")
      setisLoading(false)
      history.push("/pricing")
      return
    }

    if (!selectedFile.file) {
      setError("No image selected!")
      setisLoading(false)
      return
    }

    const downloadURL = await uploadToR2(selectedFile.file, true)
    const credits = user.balance - creditCost
    try {
      await firestore.collection("users").doc(user.id).update({
        balance: credits,
      })
    } catch (error) {
      telegram("Create, createImageDocument, error: " + error.message)
      setError("Error while updating tokens!")
      setisLoading(false)
      return null
    }

    const imageData = {
      userId: user.id,
      createdAt: serverTimestamp(),
      status: "created",
      inputImage: downloadURL,
      creditCost: creditCost,
    }

    try {
      const imagesCollectionRef = firestore
        .collection("users")
        .doc(user.id)
        .collection("images")

      const docRef = await imagesCollectionRef.add(imageData)
      setisLoading(false)
      return docRef.id
    } catch (error) {
      telegram("Create.js, createImageDocument, error: " + error.message)
      setError("Error while creating image!")
      setisLoading(false)
      return null
    }
  }

  const handleDeleteClick = (event) => {
    event.stopPropagation()
    event.preventDefault()
    setSelectedFile({ file: null })
  }

  return (
    <div className="w-full h-full flex flex-col items-center bg-bg-color text-white flex-grow">
      <div className="h-full px-6 w-full flex lg:flex-row flex-col">
        <div className="w-full lg:h-full sm:min-w-fit lg:max-w-xs lg:mr-1 lg:pr-4 lg:border-r border-neutral-700 pt-6">
          <div className="flex justify-center py-1 bg-neutral-800 border border-neutral-700 rounded-lg">
            <img alt="credits" className="w-6" src="/credits.png"></img>
            {user ? user.balance : "0"}
          </div>

          <div>
            <div className="flex items-center mt-6">
              <p className="font-bold my-2 mr-2">Image</p>
            </div>

            <div
              className="file-uploader"
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            ></div>

            <div
              className={
                selectedFile.file
                  ? "w-full max-h-64 relative"
                  : "flex flex-col items-center justify-center w-full h-32 border border-neutral-700 border-dashed rounded-lg cursor-pointer bg-neutral-800 hover:bg-neutral-700"
              }
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <label htmlFor="dropzone-file" className="w-full h-full">
                {selectedFile.file ? (
                  <div className="relative">
                    <img
                      className="max-h-64 rounded-lg cursor-pointer bg-neutral-800 hover:bg-gray-100"
                      src={selectedFile.fileUrl}
                      alt="preview"
                    />
                    <div
                      className="absolute text-xs px-2 left-1 top-1 w-14 bg-neutral-800/70 p-1 rounded-lg font-bold cursor-pointer hover:bg-opacity-80 hover:text-black"
                      onClick={handleDeleteClick}
                    >
                      Delete
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <>
                      <svg
                        className="w-8 h-8 mb-4 text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 16"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                        />
                      </svg>

                      <p className="mb-2 px-2 text-sm text-white text-center">
                        Click or drag and drop your image
                      </p>
                    </>
                  </div>
                )}
              </label>
              <input
                type="file"
                id="dropzone-file"
                className="hidden"
                onChange={handleFileChange}
              />
            </div>
          </div>

          <button
            type="submit"
            disabled={isLoading}
            onClick={user ? createImageDocument : () => setShowPopup(true)}
            className="mt-8 w-full h-12 rounded-lg font-bold text-white bg-gradient-to-r from-cyan-500 to-blue-500 via-purple-500 hover:from-black hover:border hover:border-white hover:bg-black hover:text-white background-animate"
          >
            <div className="flex flex-row place-content-center">
              {isLoading ? (
                <div className="flex justify-center items-center">
                  <p className="pr-2">Loading</p>
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5  text-white animate-spin  fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </div>
              ) : (
                <span>Geoguess</span>
              )}
            </div>
          </button>

          <div className="flex flex-col items-center justify-center my-4 py-1 bg-neutral-800 border border-neutral-700 rounded-lg text-sm text-neutral-400">
            <p></p>
            {creditCost ? (
              <p>Will cost {creditCost} credits</p>
            ) : (
              <p className="text-red-800">Error</p>
            )}
          </div>

          {error && (
            <div
              class="flex items-center p-4 mb-4 text-sm border rounded-lg bg-gray-800 text-red-400 border-red-800"
              role="alert"
            >
              <div> {error}</div>
            </div>
          )}
        </div>

        <div className="h-full w-full lg:ml-4 mt-6">
          <Recent />
        </div>
      </div>
      <Popup show={showPopup} onClose={() => setShowPopup(false)} />
    </div>
  )
}
