import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/analytics"
import "firebase/compat/storage"

import { getStripePayments } from "@stripe/firestore-stripe-payments"

const app = firebase.initializeApp({
  apiKey: "AIzaSyAPa40i6QWVeawF7LR6zLWPfQfdpPLQ1KY",
  authDomain: "geoguess-36cb5.firebaseapp.com",
  projectId: "geoguess-36cb5",
  storageBucket: "geoguess-36cb5.appspot.com",
  messagingSenderId: "1096975231608",
  appId: "1:1096975231608:web:277c8f481727c962949b8d",
})

firebase.analytics()

export const auth = app.auth()
export const firestore = app.firestore()
export const firebaseInstance = firebase
export const GoogleAuthProvider = firebase.auth.GoogleAuthProvider
export const storage = firebase.storage()

export default app

export const payments = getStripePayments(app, {
  productsCollection: "products",
  customersCollection: "users",
})

export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp
