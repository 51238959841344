import React, { useState, useEffect, useRef } from "react"
import SingleMap from "../../components/SingleMap"
import { useAuth } from "../../context/AuthContext"

export default function Recent({ customImages = null }) {
  const { images: contextImages } = useAuth()
  const [images, setImages] = useState([])
  const mapRefs = useRef([])

  useEffect(() => {
    if (customImages) {
      setImages(customImages)
    } else {
      setImages(contextImages)
    }
  }, [customImages, contextImages])

  const dummyImages = [
    {
      id: "dummy1",
      status: "done",
      latitudes: [43.72344207763672],
      longitudes: [10.395575523376465],
      probabilities: [0.04607060179114342],
      inputImage:
        "https://r2.geoguess.me/inputs/26fe122c-d2c6-4d1d-b55b-6a4775cc6f03-pisa.jpg",
    },
    // {
    //   id: "dummy2",
    //   status: "done",
    //   latitudes: [43.723697662353516],
    //   longitudes: [10.395373344421387],
    //   probabilities: [0.045998867601156235],
    //   inputImage:
    //     "https://r2.geoguess.me/inputs/26fe122c-d2c6-4d1d-b55b-6a4775cc6f03-pisa.jpg",
    // },
  ]

  const displayImages = (
    images && images.length > 0 ? images : dummyImages
  ).filter((image) => !image.deleted)

  return (
    <div className="h-full w-full">
      {displayImages && (
        <div className="grid grid-cols-1 gap-4">
          {displayImages.map((image, index) => (
            <div key={image.id}>
              {image.status === "done" ? (
                <div className="relative">
                  <SingleMap
                    key={index}
                    coordinates={{
                      latitudes: image.latitudes,
                      longitudes: image.longitudes,
                      probabilities: image.probabilities,
                    }}
                    inputImage={image.inputImage}
                    mapRef={(el) => (mapRefs.current[index] = el)}
                  />
                </div>
              ) : (
                <div>Loading...</div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
