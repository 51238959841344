import React, { useState } from "react"
import { useAuth } from "../../context/AuthContext"
import Popup from "../Sign/Popup"

export default function Banner() {
  const { user } = useAuth()
  const [showPopup, setShowPopup] = useState(false)

  return (
    <div>
      {!user && (
        <div className="w-full flex flex-col items-center justify-center lg:mt-20 bg-bg-color text-white">
          <div className="w-11/12 2xl:w-10/12 flex flex-col md:gap-16 md:flex-row">
            <div className="flex flex-col justify-center mt-10 lg:mt-0 ">
              <h1 className="font-bold text-white text-4xl sm:text-6xl">
                Geoguess the location of an image with&nbsp;
                <span className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
                  AI Magic
                </span>
              </h1>
              <div className="mb-10">
                <h2 className="mb-6 text-md sm:text-lg mt-5 font-medium text-white">
                  GeoguessAI.com uses AI to guess the location of an image.
                  Upload an image and see if the AI can guess the location.
                </h2>

                <button
                  onClick={() => setShowPopup(true)}
                  className="w-52 h-12 self-center rounded-lg font-bold text-white bg-gradient-to-r via-blue-500  to-emerald-600 from-sky-400 hover:from-black hover:border hover:border-white hover:bg-black hover:text-white background-animate"
                >
                  Get 3 free credits →
                </button>

                <span className="flex text-sm mt-2">
                  ✨be one of the{" "}
                  <span className="font-bold"> &nbsp;first users&nbsp;</span>
                </span>
              </div>
            </div>

            <div className="md:mt-12 lg:mt-0 w-full h-full">
              <img
                alt="Some Wallpaper"
                src="https://r2.geoguessai.com/social_share.png"
                className="w-full h-full rounded-lg"
              />
            </div>
          </div>
        </div>
      )}
      <Popup show={showPopup} onClose={() => setShowPopup(false)} />
    </div>
  )
}
