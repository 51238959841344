import React from "react"
import "./assets/App.css"
import Dashboard from "./pages/Dashboard/Dashboard"
import Header from "./pages/Landingpage/Header"
import LandingHero from "./pages/Landingpage/Banner"
import Register from "./pages/Sign/Register"
import Login from "./pages/Sign/Login"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import ForgotPassword from "./pages/Sign/ForgotPassword"
import { AuthProvider } from "./context/AuthContext"
import PrivateRoute from "./components/PrivateRoute"
import Pricing from "./components/Pricing"
import Popup from "./pages/Sign/Popup"
import Admin from "./pages/Dashboard/Admin"
import Success from "./pages/Dashboard/Success"
import { Helmet, HelmetProvider } from "react-helmet-async"
import Footer from "./pages/Landingpage/Footer"

function App() {
  return (
    <HelmetProvider>
      <AuthProvider>
        <BrowserRouter>
          <div className="flex flex-col min-h-screen bg-bg-color">
            <Switch>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/register">
                <Register />
              </Route>
              <Route path="/forgot-password">
                <ForgotPassword />
              </Route>

              <Route>
                <Header />
                <Route exact path="/">
                  <Helmet>
                    <title>
                      Geoguess AI - #1 Get location of any image with AI
                    </title>
                    <meta
                      name="description"
                      content="Geoguess AI is the #1 tool to get the location of any image using AI. Upload your image and let our AI technology determine the location of the image."
                    />
                    <link rel="canonical" href="https://geoguessai.com/" />
                  </Helmet>
                  <Popup />

                  <LandingHero />
                  <Dashboard />
                  <Footer />
                </Route>
                <Route exact path="/dashboard/">
                  <Helmet>
                    <title>Geoguess AI - Dashboard</title>
                    <meta
                      name="description"
                      content="Access your Geoguess AI dashboard. Utilize the leading AI technology for image location detection."
                    />
                    <link
                      rel="canonical"
                      href="https://geoguessai.com/dashboard"
                    />
                  </Helmet>
                  <Dashboard />
                </Route>

                <Route exact path="/pricing">
                  <Helmet>
                    <title>
                      Pricing - Geoguess AI - #1 Get location of any image with
                      AI
                    </title>
                    <meta
                      name="description"
                      content="Explore Geoguess AI's flexible pricing options for our advanced AI technology. Choose from subscription plans that fit your needs or one time purchases."
                    />
                    <link
                      rel="canonical"
                      href="https://geoguessai.com/pricing"
                    />
                  </Helmet>
                  <Pricing />
                </Route>
                <Route exact path="/success">
                  <Success />
                </Route>
                <PrivateRoute path="/admin">
                  <Admin />
                </PrivateRoute>
              </Route>
            </Switch>
          </div>
        </BrowserRouter>
      </AuthProvider>
    </HelmetProvider>
  )
}

export default App
